// 'use strict';

angular.module('kljDigitalLibraryApp', [
        'kljDigitalLibraryApp.auth',
        'kljDigitalLibraryApp.admin',
        'kljDigitalLibraryApp.constants',
        'ngCookies',
        'ngResource',
        'ngSanitize',
        'ui.router',
        'ui.bootstrap',
        'validation.match',
        'bgDirectives',
        'element-infinite-scroll',
        // 'angularMoment',
        'ngToast',
        'ngStorage',
        'ngPrint',
        'infinite-scroll'
    ])
    .config(["$urlRouterProvider", "$locationProvider", "$stateProvider", function($urlRouterProvider, $locationProvider, $stateProvider) {
        $urlRouterProvider
            .otherwise('/');

        $stateProvider

        // HOME STATES AND NESTED VIEWS ========================================
        // .state('index', {
        //     url: '/',
        //     templateUrl: 'app/home/KLJ.homeView.html',
        //      controller: 'homeCtrl',
        //      data: {
        //         pageTitle: 'KLJ-Home'
        //       }
        // })

        // nested list with custom controller
            .state('inner', {
            url: '/inner',
            templateUrl: 'app/detail/KLJ.detailView.html',
            controller: 'innerCtrl',
            data: {
                pageTitle: 'KLJ-Inner'
            }
        })

        $locationProvider.html5Mode(true);
    }])

.run(["$window", "$rootScope", "$state", function($window, $rootScope, $state) {

    // var windowElement = angular.element($window);
    // windowElement.on('onbeforeunload', function (event) {
    //     // do whatever you want in here before the page unloads.        

    //     // the following line of code will prevent reload or navigating away.
    //     alert();
    //     event.preventDefault();
    // });

    if (typeof config != "undefined") {
        $rootScope.offlineApp = !config.onlineApp;
        // .run(['$state', function ($state) {
        $state.transitionTo('index.home');
        // }])
    } else {
        $rootScope.offlineApp = false;
    }
    $rootScope.$on('$stateChangeStart', function(event, next, prev) {

        switch (next.name) {

            case 'index.home':
                window.onbeforeunload = null;
                break;

            case 'index.support':
                window.onbeforeunload = null;
                break;

            case 'index.instructions':
                window.onbeforeunload = null;
                break;

            case 'index.home':
                window.onbeforeunload = null;
                break;

            case 'index.notifications':
                window.onbeforeunload = null;
                break;

            case 'index.order':
                window.onbeforeunload = null;
                break;

            case 'index.subscription':
                window.onbeforeunload = null;
                break;

            case 'index.subscriptionDetails':
                window.onbeforeunload = null;
                break;

            case 'index.subscriptionOrderDetails':
                window.onbeforeunload = null;
                break;

            case 'index.subscriptionHistory':
                window.onbeforeunload = null;
                break;

            case 'main.subscriptions':
                window.onbeforeunload = null;
                break;

            case 'main.subscription-details':
                window.onbeforeunload = null;
                break;

            case 'index.subscription-plan-details':
                window.onbeforeunload = null;
                break;

            case 'main.aboutUs':
                window.onbeforeunload = null;
                break;

            case 'main.trial_version':
                window.onbeforeunload = null;
                break;

            case 'main.web_edition':
                window.onbeforeunload = null;
                break;

            case 'main.cd-rom_edition':
                window.onbeforeunload = null;
                break;

            case 'main.user_guide':
                window.onbeforeunload = null;
                break;

            case 'index.account-detail':
                window.onbeforeunload = null;
                break;

            case 'login':
                window.onbeforeunload = null;
                break;

            case 'main.orderSummary':
                window.onbeforeunload = null;
                break;

            case 'main.access_code':
                window.onbeforeunload = null;
                break;

            default:
                window.onbeforeunload = function(e) {

                    return 'Are you sure to leave this page?';
                };

        }
    });

}])

.directive('updateTitle', ['$rootScope', '$timeout',
    function($rootScope, $timeout) {
        return {
            link: function(scope, element) {

                var listener = function(event, toState) {

                    var title = 'Default Title';
                    if (toState.data && toState.data.pageTitle) title = toState.data.pageTitle;

                    $timeout(function() {
                        element.text(title);
                    }, 0, false);
                };

                $rootScope.$on('$stateChangeSuccess', listener);
            }
        };
    }
])